<template>
  <KTCard
    :page="page"
    :isForm="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Nacionalidade: <span class="text-danger">*</span>
        </template>
        <KTSelect
          :options="form_options.nacionalidade"
          v-model="form.idpais_nacionalidade"
          required
        />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Número de inscrição: <span class="text-danger">*</span>
        </template>
        <b-form-input size="lg" v-model="form.numero_inscricao" disabled />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Nome completo: <span class="text-danger">*</span>
        </template>
        <b-form-input size="lg" v-model="form.nome" disabled />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>Sexo: <span class="text-danger">*</span></template>
        <KTSelect :options="form_options.sexo" v-model="form.sexo" required />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>E-mail: <span class="text-danger">*</span></template>
        <b-form-input
          size="lg"
          type="email"
          v-model="form.email"
          placeholder="Digite o email"
          autocomplete="off"
          required=""
        />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>País: <span class="text-danger">*</span></template>
        <KTSelect :options="form_options.pais" v-model="form.idpais" required />
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>Estado: <span class="text-danger">*</span></template>
        <KTSelect
          :options="form_options.estado"
          v-model="form.idestado"
          required
        />
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
      >
        <template #label>Cidade: <span class="text-danger">*</span></template>
        <KTSelect
          :options="form_options.municipio"
          v-model="form.idmunicipio"
          required
        />
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import CommonDataService from "@/core/services/commondata.service";
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "ProfileForm",
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-id-card",
        title: "Dados Pessoais",
        description: `Aqui você pode alterar seus dados pessoais. Atenção ao seu
          e-mail, pois é através dele que você acessa o sistema, dessa forma
          lembre-se de usar um e-mail pessoal e de atualizá-lo caso não consiga
          mais acessá-lo. Lembre-se de sempre deixar seus dados atualizados.`
      },
      manual: {
        session: "profile",
        tipo: 0
      },
      apiResource: "profile",
      parentRoute: { name: "inicio" },
      form: {},
      form_options: {
        nacionalidade: [],
        pais: [],
        estado: [],
        municipio: [],
        sexo: [
          { value: 1, text: "Masculino" },
          { value: 2, text: "Feminino" },
          { value: 3, text: "Outros" },
          { value: 4, text: "Não informado" }
        ]
      }
    };
  },
  methods: {
    onSubmit() {
      this.ApiSubmitAdd(this.form);
    },
    getPageData() {
      const requests = [
        this.Api.GetData(),
        CommonDataService.getTodosPaises(),
        CommonDataService.getPais()
      ];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_TODOSPAISES = 1;
        const R_PAIS = 2;

        this.form = res[R_GETDATA].perfil ?? {};
        this.form_options.nacionalidade = res[R_TODOSPAISES];
        this.form_options.pais = res[R_PAIS];
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    async reloadEstado(isFirst = false) {
      if (!isFirst) {
        this.form.idestado = null;
        this.form.idmunicipio = null;
      }

      this.form_options.municipio = [];
      if (this.form.idpais) {
        this.form_options.estado = await CommonDataService.getEstado(
          this.form.idpais
        );
      } else {
        this.form_options.estado = [];
      }
    },
    async reloadMunicipio(isFirst = false) {
      if (!isFirst) {
        this.form.idmunicipio = null;
      }
      if (this.form.idestado) {
        this.form_options.municipio = await CommonDataService.getMunicipio(
          this.form.idestado
        );
      } else {
        this.form_options.municipio = [];
      }
    }
  },
  watch: {
    "form.idpais": function (newValue, oldValue) {
      this.reloadEstado(oldValue === undefined);
    },
    "form.idestado": function (newValue, oldValue) {
      this.reloadMunicipio(oldValue === undefined);
    }
  },
  mounted() {
    this.getPageData();
    this.initManual(this.manual.session, this.manual.tipo);
  }
};
</script>
